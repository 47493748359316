import {
  Avatar,
  Box,
  styled,
  Typography,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  useMediaQuery,
  IconButton,
} from '@mui/material';

import { formatBalance, formatNumber } from 'utils/numbers';
import { formatAddress } from 'utils/address';
import { getFullMediaUrl } from 'utils/image';
import { useWallet } from 'context/WalletContext';
import { openLink } from 'utils/openLink';
import { GMI_LINKS } from 'constants';

import {
  AetherGemIcon,
  AmethystsGemIcon,
  DiamondGemIcon,
  EmeraldGemIcon,
  GoldIcon,
  NRGIcon,
  PearlGemIcon,
  QuartzGemIcon,
  RubyGemIcon,
  SapphireGemIcon,
  TopazGemIcon,
  ManaGemIcon,
} from 'components/ImageComponent';
import Blockie from 'components/Blockie';
import { ToolTip } from 'components/StyledComponents';

const CustomHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  fontSize: 18,
  fontWeight: 500,
  backgroundColor: theme.palette.background.filter,
  borderTop: `1px solid ${theme.palette.border.decorative}`,
  height: 48,

  '@media (max-width: 600px)': {
    fontSize: 14,
    height: 64,

    '&:first-of-type': {
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const CustomTableCell = styled(TableCell)(({ theme, color, bigger, align }) => ({
  padding: theme.spacing(0, 1),
  textAlign: align ?? 'center',
  fontSize: bigger === 'true' ? 18 : 16,
  fontWeight: 500,
  height: 48,
  color: color === 'true' ? theme.palette.link.primary : theme.palette.text.main,
  borderTop: `1px solid ${theme.palette.border.decorative}`,

  '@media (max-width: 600px)': {
    fontSize: bigger === 'true' ? 14 : 12,
    height: 56,
  },
}));

const CenteredFlexBox = styled(Box)(({ justify, fullwidth, maxwidth }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: justify ?? 'flex-start',
  textAlign: 'left',
  width: '100%',
  maxWidth: fullwidth === 'true' ? 'auto' : maxwidth ? maxwidth : 180,
}));

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: 34,
  height: 34,
  marginRight: theme.spacing(1),
}));

const CreatorCollectionLink = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  cursor: 'pointer',
}));

const ScoreBox = styled(Box)(({ theme, justify }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  justifyContent: justify ?? 'center',
}));

export const XDataRow = ({ rank, avatar, username, tweets, views, points, address }) => {
  const below480 = useMediaQuery('(max-width: 480px)');

  return (
    <TableRow>
      <CustomTableCell>{rank}</CustomTableCell>
      <CustomTableCell>
        <CenteredFlexBox fullwidth="true">
          {avatar ? (
            <CustomAvatar src={avatar} alt={username} />
          ) : (
            <Blockie size={4} customAddress={address} marginRight={1} />
          )}
          <Box>
            <Typography fontSize={14} fontWeight={600}>
              @{username}
            </Typography>
            {below480 && (
              <Typography fontSize={11} fontWeight={500}>
                {tweets} TWEETS
              </Typography>
            )}
          </Box>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell>
        {below480 ? (
          <ScoreBox>
            <Typography fontSize={below480 ? 11 : 14} fontWeight={500} sx={{ textWrap: 'nowrap' }}>
              {Math.trunc(points).toLocaleString()}
            </Typography>
            <ToolTip title="Amethysts">
              <IconButton>
                <AmethystsGemIcon width={18} height={18} />
              </IconButton>
            </ToolTip>
          </ScoreBox>
        ) : (
          tweets
        )}
      </CustomTableCell>
      {!below480 && (
        <>
          <CustomTableCell>{Number(views).toLocaleString()}</CustomTableCell>
          <CustomTableCell bigger="true">
            <ScoreBox>
              {Math.trunc(points).toLocaleString()}{' '}
              <ToolTip title="Amethysts">
                <IconButton>
                  <AmethystsGemIcon width={24} height={24} />
                </IconButton>
              </ToolTip>
            </ScoreBox>
          </CustomTableCell>
        </>
      )}
    </TableRow>
  );
};

export const LiquidityDataRow = ({ rank, address, liquidity, points, refPoint }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const below1280 = useMediaQuery('(max-width: 905px)');

  return (
    <TableRow>
      <CustomTableCell>{rank}</CustomTableCell>
      <CustomTableCell>
        <CenteredFlexBox fullwidth="true">
          <Blockie size={4} customAddress={address} marginRight={2} />
          <Typography fontSize={16} fontWeight={600}>
            {below1280
              ? below480
                ? formatAddress(address, 8)
                : formatAddress(address, 10)
              : address}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell>
        {below480 ? (
          <Box textAlign="left" paddingLeft="15%">
            <Typography fontSize={11} fontWeight={500}>
              ${Math.trunc(liquidity).toLocaleString()}
            </Typography>
            <ScoreBox justify="flex-start">
              <Typography
                fontSize={below480 ? 11 : 14}
                fontWeight={500}
                sx={{ textWrap: 'nowrap' }}
              >
                {Math.trunc(Number(points) + Number(refPoint)).toLocaleString()}
              </Typography>
              <ToolTip title="Sapphire">
                <IconButton>
                  <SapphireGemIcon width={18} height={18} />
                </IconButton>
              </ToolTip>
            </ScoreBox>
          </Box>
        ) : (
          `$${Math.trunc(liquidity).toLocaleString()}`
        )}
      </CustomTableCell>
      {!below480 && (
        <CustomTableCell bigger="true">
          <ScoreBox>
            {Math.trunc(Number(points) + Number(refPoint)).toLocaleString()}{' '}
            <ToolTip title="Sapphire">
              <IconButton>
                <SapphireGemIcon width={24} height={24} />
              </IconButton>
            </ToolTip>
          </ScoreBox>
        </CustomTableCell>
      )}
    </TableRow>
  );
};

export const TraderDataRow = ({ address, User, basePoints, totalPoints, rank }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const below905 = useMediaQuery('(max-width: 905px)');
  const { address: myAddress } = useWallet();

  const formatValue = (value) =>
    below905 ? formatBalance(parseInt(value), true) : formatNumber(parseInt(value));

  const { username, avatar, ensDomain, unstoppableDomain } = User;
  const trader =
    username || ensDomain || unstoppableDomain || (below905 ? formatAddress(address, 10) : address);
  const isMyAddress = (myAddress?.toLowerCase() === address).toString();

  return (
    <TableRow>
      <CustomTableCell color={isMyAddress}>{rank}</CustomTableCell>
      <CustomTableCell color={isMyAddress}>
        <CenteredFlexBox fullwidth="true">
          {avatar ? (
            <CustomAvatar src={avatar} alt={trader} />
          ) : (
            <Blockie size={4} customAddress={address} marginRight={1} />
          )}
          <Typography fontSize={16} fontWeight={600}>
            {trader}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell align="right" color={isMyAddress}>
        {below480 ? (
          <ScoreBox justify="flex-end">
            <Typography fontSize={14} fontWeight={500} sx={{ textWrap: 'nowrap' }}>
              {formatValue(totalPoints)}
            </Typography>
            <ToolTip title="Topaz">
              <IconButton>
                <TopazGemIcon width={18} height={18} />
              </IconButton>
            </ToolTip>
          </ScoreBox>
        ) : (
          <CenteredFlexBox fullwidth="true" justify="flex-end" gap={1}>
            <Box>{formatValue(basePoints)}</Box>
            <NRGIcon width={18} height={18} />
          </CenteredFlexBox>
        )}
      </CustomTableCell>
      {!below480 && (
        <CustomTableCell align="right" color={isMyAddress} bigger="true">
          <ScoreBox justify="flex-end">
            <span>{formatValue(totalPoints)}</span>
            <ToolTip title="Topaz">
              <IconButton>
                <TopazGemIcon width={24} height={24} />
              </IconButton>
            </ToolTip>
          </ScoreBox>
        </CustomTableCell>
      )}
    </TableRow>
  );
};

export const TokenHodlerDataRow = ({ rank, User, holderAddress, erc20TotalPoints }) => {
  const below905 = useMediaQuery('(max-width: 905px)');

  const { avatar, ensDomain, unstoppableDomain, username } = User || {};
  const user =
    username ||
    ensDomain ||
    unstoppableDomain ||
    (below905 ? formatAddress(holderAddress, 10) : holderAddress);

  return (
    <TableRow>
      <CustomTableCell>{rank}</CustomTableCell>
      <CustomTableCell>
        <CenteredFlexBox fullwidth="true">
          {avatar ? (
            <CustomAvatar src={avatar} alt={holderAddress} />
          ) : (
            <Blockie size={4} customAddress={holderAddress} marginRight={1} />
          )}
          <Typography fontSize={16} fontWeight={600}>
            {user}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell sx={{ textWrap: 'nowrap' }}>
        <ScoreBox>
          <span>{Math.trunc(erc20TotalPoints).toLocaleString()}</span>
          <ToolTip title="Aether">
            <IconButton>
              <AetherGemIcon width={24} height={24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

export const NftHodlerDataRow = ({ rank, User, holderAddress, nftsTotalPoints }) => {
  const below905 = useMediaQuery('(max-width: 905px)');
  const below480 = useMediaQuery('(max-width: 480px)');
  const { avatar, ensDomain, unstoppableDomain, username } = User || {};
  const user =
    username ||
    ensDomain ||
    unstoppableDomain ||
    (below905 ? formatAddress(holderAddress, 10) : holderAddress);

  return (
    <TableRow>
      <CustomTableCell>{rank}</CustomTableCell>
      <CustomTableCell>
        <CenteredFlexBox fullwidth="true">
          {avatar ? (
            <CustomAvatar src={avatar} alt={holderAddress} />
          ) : (
            <Blockie size={4} customAddress={holderAddress} marginRight={1} />
          )}
          <Typography fontSize={16} fontWeight={600}>
            {user}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell sx={{ textWrap: 'nowrap' }}>
        <ScoreBox>
          <span>{Math.trunc(nftsTotalPoints).toLocaleString()}</span>
          <ToolTip title="Rubies">
            <IconButton>
              <RubyGemIcon width={below480 ? 18 : 24} height={below480 ? 18 : 24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

export const CreatorDataRow = ({ rank, contractAddress, NftContract, basePoints, totalPoints }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const below905 = useMediaQuery('(max-width: 905px)');
  const { address: myAddress } = useWallet();

  const formatValue = (value) =>
    below905 ? formatBalance(parseInt(value), true) : formatNumber(parseInt(value));

  const { name, ownerAddress, contractThumbnailPathName } = NftContract;
  const isMyCollection = (ownerAddress === myAddress?.toLowerCase()).toString();

  return (
    <TableRow>
      <CustomTableCell color={isMyCollection}>{rank}</CustomTableCell>
      <CustomTableCell color={isMyCollection}>
        <CenteredFlexBox fullwidth="true">
          <CustomAvatar src={getFullMediaUrl(contractThumbnailPathName)} alt={name} />
          <CreatorCollectionLink
            onClick={() => openLink(`${GMI_LINKS.Webapp}collections/${contractAddress}`)}
          >
            {name || formatAddress(contractAddress, 8, 6)}
          </CreatorCollectionLink>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell color={isMyCollection} align="right">
        {below480 ? (
          <ScoreBox justify="flex-end">
            <Typography fontSize={14} fontWeight={500} sx={{ textWrap: 'nowrap' }}>
              {formatValue(totalPoints)}
            </Typography>
            <ToolTip title="Quartz">
              <IconButton>
                <QuartzGemIcon width={18} height={18} />
              </IconButton>
            </ToolTip>
          </ScoreBox>
        ) : (
          <CenteredFlexBox fullwidth="true" justify="flex-end" gap={1}>
            <Box>{formatValue(basePoints)}</Box>
            <NRGIcon width={18} height={18} />
          </CenteredFlexBox>
        )}
      </CustomTableCell>
      {!below480 && (
        <CustomTableCell color={isMyCollection} bigger="true" align="right">
          <ScoreBox justify="flex-end">
            <span>{formatValue(totalPoints)}</span>{' '}
            <ToolTip title="Quartz">
              <IconButton>
                <QuartzGemIcon width={24} height={24} />
              </IconButton>
            </ToolTip>
          </ScoreBox>
        </CustomTableCell>
      )}
    </TableRow>
  );
};

export const NftVolumeDataRow = ({ rank, address, points }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const below905 = useMediaQuery('(max-width: 905px)');

  return (
    <TableRow>
      <CustomTableCell>{rank}</CustomTableCell>
      <CustomTableCell>
        <CenteredFlexBox fullwidth="true">
          <Blockie size={4} customAddress={address} marginRight={2} />
          <Typography fontSize={16} fontWeight={600}>
            {below905
              ? below480
                ? formatAddress(address, 8)
                : formatAddress(address, 10)
              : address}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell sx={{ textWrap: 'nowrap' }}>
        <ScoreBox>
          <span>{parseInt(points).toLocaleString()}</span>
          <ToolTip title="Diamond">
            <IconButton>
              <DiamondGemIcon width={24} height={24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

export const ZealyDataRow = ({ rank, address, username, grandTotal }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const { address: myAddress } = useWallet();
  const isMyAddress = (address === myAddress?.toLowerCase()).toString();

  return (
    <TableRow>
      <CustomTableCell color={isMyAddress}>{rank}</CustomTableCell>
      <CustomTableCell color={isMyAddress}>
        <CenteredFlexBox fullwidth="true">
          <Blockie size={4} customAddress={address} />
          <Typography marginLeft={1} fontSize={16} fontWeight={600}>
            {username}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell color={isMyAddress} sx={{ textWrap: 'nowrap' }}>
        <ScoreBox>
          <span>{parseInt(grandTotal).toLocaleString()}</span>
          <ToolTip title="Pearl">
            <IconButton>
              <PearlGemIcon width={below480 ? 18 : 24} height={below480 ? 18 : 24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

export const NftPortfolioDataRow = ({ address, User, totalPoints, rank }) => {
  const below905 = useMediaQuery('(max-width: 905px)');
  const { address: myAddress } = useWallet();

  const formatValue = (value) =>
    below905 ? formatBalance(parseInt(value), true) : formatNumber(parseInt(value));

  const { username, avatar, ensDomain, unstoppableDomain } = User;
  const formattedAddress = below905 ? formatAddress(address, 10) : address;
  const user = username || ensDomain || unstoppableDomain || formattedAddress;

  const isMyAddress = (address === myAddress?.toLowerCase()).toString();

  return (
    <TableRow>
      <CustomTableCell color={isMyAddress}>{rank}</CustomTableCell>
      <CustomTableCell color={isMyAddress}>
        <CenteredFlexBox fullwidth="true">
          {avatar ? (
            <CustomAvatar src={avatar} alt={user} />
          ) : (
            <Blockie size={4} customAddress={address} marginRight={1} />
          )}
          <Typography fontSize={16} fontWeight={600}>
            {user}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell color={isMyAddress} sx={{ textWrap: 'nowrap' }}>
        <ScoreBox>
          <span>{formatValue(totalPoints)}</span>
          <ToolTip title="Emerald">
            <IconButton>
              <EmeraldGemIcon width={24} height={24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

export const PowerTweetDataRow = ({ rank, address, total, username }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  const below905 = useMediaQuery('(max-width: 905px)');
  const { address: myAddress } = useWallet();
  const isMyAddress = (address === myAddress?.toLowerCase()).toString();

  return (
    <TableRow>
      <CustomTableCell color={isMyAddress}>{rank}</CustomTableCell>
      <CustomTableCell color={isMyAddress}>
        <CenteredFlexBox fullwidth="true">
          <Blockie size={4} customAddress={address} marginRight={2} />
          <Typography fontSize={16} fontWeight={600}>
            {username
              ? username
              : below905
              ? below480
                ? formatAddress(address, 8)
                : formatAddress(address, 10)
              : address}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell color={isMyAddress} sx={{ textWrap: 'nowrap' }}>
        <ScoreBox>
          <span>{total.toLocaleString()}</span>
          <ToolTip title="Gold">
            <IconButton>
              <GoldIcon width={below480 ? 18 : 24} height={below480 ? 18 : 24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

export const ListingDataRow = ({ address, User, totalPoints, rank }) => {
  const below905 = useMediaQuery('(max-width: 905px)');
  const below480 = useMediaQuery('(max-width: 480px)');
  const { address: myAddress } = useWallet();

  const { username, avatar, ensDomain, unstoppableDomain } = User;
  const userAddress = below905
    ? below480
      ? formatAddress(address, 8)
      : formatAddress(address, 10)
    : address;
  const user = username || ensDomain || unstoppableDomain || userAddress;
  const isMyAddress = (myAddress?.toLowerCase() === address).toString();

  const formatValue = (value) =>
    below905 ? formatBalance(parseInt(value), true) : formatNumber(parseInt(value));
  const total = formatValue(totalPoints);

  return (
    <TableRow>
      <CustomTableCell color={isMyAddress}>{rank}</CustomTableCell>
      <CustomTableCell color={isMyAddress}>
        <CenteredFlexBox fullwidth="true">
          {avatar ? (
            <CustomAvatar src={avatar} alt={address} />
          ) : (
            <Blockie size={4} customAddress={address} marginRight={1} />
          )}
          <Typography fontSize={16} fontWeight={600}>
            {user}
          </Typography>
        </CenteredFlexBox>
      </CustomTableCell>
      <CustomTableCell color={isMyAddress}>
        <ScoreBox>
          <span>{total.toLocaleString()}</span>
          <ToolTip title="Mana">
            <IconButton>
              <ManaGemIcon width={below480 ? 14 : 18} height={below480 ? 20 : 24} />
            </IconButton>
          </ToolTip>
        </ScoreBox>
      </CustomTableCell>
    </TableRow>
  );
};

/********************  Main Component  ********************/
const Table = ({ headers, data, rowComponent }) => {
  const DataRow = rowComponent;

  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <CustomHeaderCell key={header.id} align={header.align ?? 'center'}>
                {header.label}
              </CustomHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <DataRow key={row.rank} {...row} />
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
