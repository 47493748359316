import React from 'react';

const Landing = React.lazy(() => import('./Landing'));
const ConfirmEmail = React.lazy(() => import('./ConfirmEmail'));
const ReferralProgram = React.lazy(() => import('./ReferralProgram'));
const S1Dashboard = React.lazy(() => import('./S1/Dashboard'));
const S1Leaderboard = React.lazy(() => import('./S1/Leaderboard'));
const Leaderboard = React.lazy(() => import('./Leaderboard'));
const XDashboard = React.lazy(() => import('./XDashboard'));
const LiquidityAirdrop = React.lazy(() => import('./LiquidityAirdrop'));
const CreatorAirdrop = React.lazy(() => import('./CreatorAirdrop'));
const TraderAirdrop = React.lazy(() => import('./TraderAirdrop'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const Boosters = React.lazy(() => import('./Boosters'));
const TokenHodler = React.lazy(() => import('./TokenHodler'));
const NftHodler = React.lazy(() => import('./NftHodler'));
const ZealyAirdrop = React.lazy(() => import('./ZealyAirdrop'));
const NftVolumeAirdrop = React.lazy(() => import('./NftVolumeAirdrop'));
const NftBridgeAirdrop = React.lazy(() => import('./NftBridgeAirdrop'));
const NftPortfolioAirdrop = React.lazy(() => import('./NftPortfolioAirdrop'));
const NftListingAirdrop = React.lazy(() => import('./NftListingAirdrop'));
const PowerTweetAirdrop = React.lazy(() => import('./PowerTweetAirdrop'));
const RewardsVault = React.lazy(() => import('./RewardsVault'));
const MemeCreatorAirdrop = React.lazy(() => import('./MemeCreatorAirdrop'));
const ReferralStats = React.lazy(() => import('./ReferralStats'));
const SingleReferralStats = React.lazy(() => import('./ReferralStats/SingleReferralStats'));

export const ROUTES = {
  Landing: '/',
  ConfirmEmail: '/confirm-email',
  EmailVerified: '/email-verified',
  Referral: '/referral',
  S1Dashboard: '/s1',
  S1Leaderboard: '/s1/leaderboard',
  Dashboard: '/dashboard',
  Leaderboard: '/leaderboard',
  XDashboard: '/x-airdrop',
  LiquidityAirdrop: '/liquidity-airdrop',
  CreatorAirdrop: '/creator-airdrop',
  TraderAirdrop: '/trader-airdrop',
  TokenHodler: '/token-hodler',
  NftHodler: '/nft-hodler',
  Boosters: '/boosters',
  ZealyAirdrop: '/zealy-airdrop',
  NftVolumeAirdrop: '/nft-volume',
  NftBridgeAirdrop: '/nft-bridge-airdrop',
  NftPortfolioAirdrop: '/nft-portfolio-airdrop',
  NftListingAirdrop: '/nft-listing-airdrop',
  PowerTweetAirdrop: '/power-tweet-airdrop',
  RewardsVault: '/rewards-vault',
  MemeCreatorAirdrop: '/meme-creator-airdrop',
  ReferralStats: '/referral-stats',
  SingleReferralStats: '/my-referral-stats',
};

export const PAGES = [
  {
    path: '/*',
    element: <Landing />,
  },
  {
    path: ROUTES.Dashboard,
    element: <Dashboard />,
  },
  {
    path: ROUTES.S1Dashboard,
    element: <S1Dashboard />,
  },
  {
    path: ROUTES.S1Leaderboard,
    element: <S1Leaderboard />,
  },
  {
    path: ROUTES.Referral,
    element: <ReferralProgram />,
  },
  {
    path: ROUTES.Leaderboard,
    element: <Leaderboard />,
  },
  {
    path: ROUTES.ConfirmEmail,
    element: <ConfirmEmail />,
  },
  {
    path: ROUTES.XDashboard,
    element: <XDashboard />,
  },
  {
    path: ROUTES.LiquidityAirdrop,
    element: <LiquidityAirdrop />,
  },
  {
    path: ROUTES.CreatorAirdrop,
    element: <CreatorAirdrop />,
  },
  {
    path: ROUTES.TraderAirdrop,
    element: <TraderAirdrop />,
  },
  {
    path: ROUTES.Boosters,
    element: <Boosters />,
  },
  {
    path: ROUTES.TokenHodler,
    element: <TokenHodler />,
  },
  {
    path: ROUTES.NftHodler,
    element: <NftHodler />,
  },
  {
    path: ROUTES.ZealyAirdrop,
    element: <ZealyAirdrop />,
  },
  {
    path: ROUTES.NftVolumeAirdrop,
    element: <NftVolumeAirdrop />,
  },
  {
    path: ROUTES.NftBridgeAirdrop,
    element: <NftBridgeAirdrop />,
  },
  {
    path: ROUTES.NftPortfolioAirdrop,
    element: <NftPortfolioAirdrop />,
  },
  {
    path: ROUTES.NftListingAirdrop,
    element: <NftListingAirdrop />,
  },
  {
    path: ROUTES.PowerTweetAirdrop,
    element: <PowerTweetAirdrop />,
  },
  {
    path: ROUTES.RewardsVault,
    element: <RewardsVault />,
  },
  {
    path: ROUTES.MemeCreatorAirdrop,
    element: <MemeCreatorAirdrop />,
  },
  {
    path: ROUTES.ReferralStats,
    element: <ReferralStats />,
  },
  {
    path: ROUTES.SingleReferralStats,
    element: <SingleReferralStats />,
  },
];

export const NAV_LINKS = [
  {
    name: 'Airdrop Dashboard',
    path: ROUTES.Dashboard,
  },
  {
    name: 'Rewards Vault',
    path: ROUTES.RewardsVault,
  },
  {
    name: 'Referral Program',
    path: ROUTES.Referral,
  },
  {
    name: 'Referral Stats',
    path: ROUTES.ReferralStats,
  },
  {
    name: 'GMI Marketplace',
    path: 'https://app.gonnamakeit.com',
  },
  {
    name: 'Resources',
    path: '',
    submenus: [
      {
        name: 'Docs',
        path: `https://docs.gonnamakeit.com/docs/airdrop/gmi-airdrop-season-5`,
      },
      {
        name: 'Energi Website',
        path: 'https://energi.world/',
      },
    ],
  },
];
